const StateCalculator = {
    AmountField: 1,
    ActiveOptionSize: '85x50',
    ActiveOptionMaterial: 'DREAMCARD azure 460g jednostronny',
    Corners: false,
    optionSize: [
        '85x50',
        '85x55',
        '90x50',
        '90x55'
    ],
    optionMaterial: [
        'DREAMCARD azure 460g jednostronny',
        'DREAMCARD błękit królewski 460g jednostronny',
        'DREAMCARD camel 460g jednostronny'
    ],
}

export {StateCalculator}
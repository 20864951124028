const stateInput = {
  ImageUpload: {
    file: [],
    imagePreviewUrl: "",
  },
  ProjectImages: [],
  RenderImages: [],
  QRCode: "",
  disableTools: true,
  activeInput: 1,
  bgCard: require("../../assets/img/1.jpeg"),
  // bgCard: 'gray',
  bgColor: "gray",
  toFront: true,
  disabledButtonInput: false,
  imagesSlider: [
    require("../../assets/img/1.jpeg"),
    require("../../assets/img/2.jpeg"),
    require("../../assets/img/3.png"),
    require("../../assets/img/4.png"),
    require("../../assets/img/5.png"),
    require("../../assets/img/6.png"),
    require("../../assets/img/8.png"),
    require("../../assets/img/9.png"),
    require("../../assets/img/10.png"),
    require("../../assets/img/11.png"),
    require("../../assets/img/12.png"),
    require("../../assets/img/13.png"),
    require("../../assets/img/14.png"),
    require("../../assets/img/15.png"),
    require("../../assets/img/16.png"),
    require("../../assets/img/17.png"),
    require("../../assets/img/18.png"),
    require("../../assets/img/19.png"),
    require("../../assets/img/20.png"),
    require("../../assets/img/21.png"),
    require("../../assets/img/22.png"),
    require("../../assets/img/23.png"),
    require("../../assets/img/24.png"),
    require("../../assets/img/25.png"),
  ],
  inputs: [
    {
      id: 0,
      text: "Jaś Kowalski",
      bold: false,
      style: false,
      underline: false,
      fontSize: 14,
      color: "#225699",
      activeFontFamily: "Open Sans",
      remove: false,
    },
    {
      id: 1,
      text:
        "Za udział w życiu przedszkolnym, wspólne gry i zabawy. Życzymy Ci udanych wakacji, dużo uśmiechu i radości na co dzień.",
      bold: false,
      style: false,
      underline: false,
      fontSize: 14,
      color: "#225699",
      activeFontFamily: "Open Sans",
      remove: false,
    },
    {
      id: 2,
      text: "Życzymy Ci udanych wakacji, dużo uśmiechu i radości na co dzień.",
      bold: false,
      style: false,
      underline: false,
      fontSize: 14,
      color: "#225699",
      activeFontFamily: "Open Sans",
      remove: false,
    },
    // {
    //   id: 1,
    //   text: "505226709",
    //   bold: false,
    //   style: false,
    //   underline: false,
    //   fontSize: 14,
    //   color: "#225699",
    //   activeFontFamily: "Open Sans",
    //   remove: false,
    // },
    // {
    //   id: 2,
    //   text: "ul. Warsztatowa 10",
    //   bold: false,
    //   style: false,
    //   underline: false,
    //   fontSize: 14,
    //   color: "#225699",
    //   activeFontFamily: "Open Sans",
    //   remove: false,
    // },
    // {
    //   id: 3,
    //   text: "21-040 Świdnik",
    //   bold: false,
    //   style: false,
    //   underline: false,
    //   fontSize: 14,
    //   color: "#225699",
    //   activeFontFamily: "Open Sans",
    //   remove: false,
    // },
    // {
    //   id: 4,
    //   text: "drukurs@gmail.com",
    //   bold: false,
    //   style: false,
    //   underline: false,
    //   fontSize: 14,
    //   color: "#225699",
    //   activeFontFamily: "Open Sans",
    //   remove: false,
    // },
  ],
};
export default stateInput;

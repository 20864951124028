import React, { useEffect } from "react";
import { ContextInput } from "./../../context/Input/ContextInput.jsx";
import ImageUpload from "../ImageUpload/ImageUpload";

import Draggable from "react-draggable";
import "./Field.css";
import QRCode from "qrcode.react";
import ImageUploading from "react-images-uploading";

// import { makeStyles } from "@material-ui/core/styles";
import { Stage, Layer, Image } from "react-konva";
import { Resizable } from "re-resizable";
import { Rnd } from "react-rnd";
import RenderImages from "../RenderImages/RenderImages";
// import { ControlledCarousel } from "../../modules/Carousel/Carousel";

export const Field = React.memo(() => {
  var QRcode = require("qrcode.react");
  const { dispatch, ...stateInputs } = React.useContext(ContextInput);
  const QRCodeValue = stateInputs.state.QRCode;
  console.log(QRCodeValue);
  // console.log(QRCode, 'qrcode')
  const inputs = stateInputs.state.inputs;
  const uploadImage = stateInputs.state.ImageUpload.imagePreviewUrl;
  // const uploadFile = stateInputs.state.ImageUpload.file;

  const [qrWidth, setQrWidth] = React.useState(128);
  const [qrHeight, setQrHeight] = React.useState(128);
  const [width, setWidth] = React.useState(300);
  const [height, setHeight] = React.useState(200);
  const dragUrl = React.useRef();
  const stageRef = React.useRef();
  const isRender = false;
  const projectImages = stateInputs.state.ProjectImages;
  const renderImages = stateInputs.state.RenderImages;
  // console.log(renderImages);
  // console.log(renderImages, "renderImages");
  // console.log(projectImage, "projectImage");
  // console.log(renderImg, "renderImg");

  // const data = projectImage();
  // const res = data.data_url;
  // console.log(res, "res")

  const getBackground = () => {
    //console.log(stateInputs.state.backgrounds);
    return (
      (stateInputs.state.bgColor === "gray" && {
        backgroundImage: `url(${stateInputs.state.bgCard})`,
      }) || { backgroundColor: stateInputs.state.bgColor }
    );
  };

  // useEffect(() => {
  //   if (QRCodeValue === "") {
  //     return <QRcode value="" style={{ display: "none" }} className="none" />;
  //   }
  // }, [QRCodeValue]);
  
  return (
    <div>
      <div className="image__container">
        <ImageUpload />
      </div>
      <div className="field__wrapper">
        <div
          className="parents"
          id="parents"
          style={{
            ...getBackground(),
          }}
        >
          <div className="field__container">
            {inputs.map((input, index) => {
              // console.log(stateInputs.state.activeInput, input.id )

              return (
                // <div className="drag-container">
                <Draggable
                  defaultPosition={
                    window.innerWidth < 768
                      ? { x: 180, y: 70 }
                      : { x: 0, y: 250 }
                  }
                  key={index}
                  bounds="parent"
                  // grid={[5, 5]}
                  scale={1}
                >
                  <Resizable
                    onResizeStart={(e) => {
                      e.stopPropagation();
                    }}
                    defaultSize={{
                      width: 450,
                    }}
                    maxWidth={500}
                    enable={{
                      right: true,
                      bottom: false,
                      left: false,
                      top: false,
                    }}
                    style={{
                      zIndex: input.zIndex ? "1" : "0",
                    }}
                  >
                    <div
                      key={index}
                      style={{
                        fontWeight: input.bold ? "700" : "400",
                        fontStyle: input.style ? "italic" : "normal",
                        textDecoration: input.underline ? "underline" : "none",
                        fontSize: `${input.fontSize}px`,
                        color: input.color,
                        fontFamily: input.activeFontFamily,
                        border: "1px solid transparent",
                        borderColor:
                          stateInputs.state.activeInput === input.id
                            ? "#fff"
                            : "transparent",
                      }}
                      onClick={() => {
                        dispatch({
                          type: "ACTIVE_INPUTS",
                          payload: {
                            id: index,
                          },
                        });
                      }}
                    >
                      {input.text}
                    </div>
                  </Resizable>
                </Draggable>
              );
            })}

            <div>
              <Rnd
                onResizeStart={(e) => {
                  e.stopPropagation();
                  // setResizing(true);
                }}
                onResizeStop={(e, direction, ref, d) => {
                  e.stopPropagation();
                  // setResizing(false);
                  setWidth(width + d.width);
                  setHeight(height + d.height);
                }}
                // lockAspectRatio={true}
                bounds=".parents"
                // enableResizing={{
                //   top: false,
                //   left: false,
                //   right: false,
                //   bottom: false,
                //   topLeft: false,
                //   topRight: false,
                //   bottomLeft: false,
                //   bottomRight: true
                // }}
              >
                {/* <div
                  onDrop={(e) => {
                    // register event position
                    stageRef.current.setPointersPositions(e);
                    // add image
                    setImages(
                      images.concat([
                        {
                          ...stageRef.current.getPointerPosition(),
                          src: dragUrl.current,
                          
                        },
                      ])
                    );
                    console.log(setImages)
                  }}
                  onDragOver={(e) => e.preventDefault()}
                >
                  {images.map((image) => {
                    return <img src={dragUrl.current} alt="" />;
                  })}
                </div> */}

                {/* {projectImages &&
                  projectImages.map((image, index) => {
                    return (
                      <img
                        onDragStart={(e) => {
                          e.preventDefault();
                        }}
                        style={{
                          width: width + "px",
                          height: height + "px",
                        }}
                        key={index}
                        src={image["data_url"]}
                        alt="Your project"
                        // className={uploadImage ? "" : "none"}
                      />
                    );
                  })} */}
              </Rnd>

              <RenderImages/>

              <Rnd
                onResizeStart={(e) => {
                  e.stopPropagation();
                  // setResizing(true);
                }}
                onResizeStop={(e, direction, ref, d) => {
                  e.stopPropagation();
                  // setResizing(false);
                  setQrWidth(qrWidth + d.width);
                  setQrHeight(qrHeight + d.height);
                }}
                lockAspectRatio={true}
                bounds=".parents"
              >
                {/* if(QRCodeValue === false) {
                  <QRcode
                  value=""
                  style={{display: 'none'}}
                  className="none"
                />
                } else {
                  <QRcode
                    onDragStart={(e) => {
                      e.preventDefault();
                    }}
                    value={QRCodeValue}
                    style={{
                      width: qrWidth + "px",
                      height: qrHeight + "px",
                    }}
                    className={!QRCodeValue ? "none" : "qrCode"}
                  />
                } */}

                {QRCodeValue === "" ? (
                  <QRcode
                    value=""
                    style={{ display: "none" }}
                    className="none"
                  />
                ) : (
                  <QRcode
                    onDragStart={(e) => {
                      e.preventDefault();
                    }}
                    value={QRCodeValue}
                    style={{
                      width: qrWidth + "px",
                      height: qrHeight + "px",
                    }}
                    className={!QRCodeValue ? "none" : "qrCode"}
                  />
                )}
                {/* <QRcode
                  onDragStart={(e) => {
                    e.preventDefault();
                  }}
                  value={QRCode}
                  style={{
                    width: qrWidth + "px",
                    height: qrHeight + "px",
                  }}
                  className={!QRCode ? "none" : "qrCode" || !QRCode ? undefined : "qrCode"}
                /> */}
              </Rnd>
            </div>
          </div>
        </div>
        <div className=""></div>
      </div>
    </div>
  );
});

import React from "react";
import { NavLink } from "react-router-dom";
import Drawer from "../../modules/Drawer/Drawer";
import Button from "@material-ui/core/Button";
import "./style.css";
export default React.memo(() => {
  return (
    <div className="MainContainer">
      <Drawer />
      <div className="MainText">
        <div className="MainTitle">
          <h1>Kreator dyplomy</h1>
        </div>
        <div className="MainSubTitle">
          <p>Simple to create</p>
        </div>
        <div className="LinkBtn">
          <Button variant="contained" color="primary" size="large" className="Link-btn">
            <NavLink to="/constructor" className="Link-btn">
              Create products
            </NavLink>
          </Button>
        </div>
      </div>
    </div>
  );
});

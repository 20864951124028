import React from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import Home from "./routes/Home/Home.jsx";
import Constructor from "./routes/Constructor/Constructor.jsx";
import Calculator from "./routes/Calculator/Calculator.jsx";
import ContactForm from "./routes/ContactForm/ContactForm.jsx";
// import { TransitionGroup, CSSTransition } from "react-transition-group";
// import {useLocation} from 'react-router-dom'

function App() {
  
  // window.onbeforeunload = function(e) {
  //     return 'Dialog text here.';
  //   }
  // let location = useLocation();


  return (
    <div>
      {/* <TransitionGroup> */}
        {/* <CSSTransition className="fade" timeout={300} key={location.key}>/ */}
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/constructor" component={Constructor} />
            <Route path="/calculator" component={Calculator} />
            <Route path="/contact" component={ContactForm} />
            <Route
              render={() => (
                <h1
                  style={{
                    color: "red",
                    textAlign: "center",
                    fontSize: "60px",
                  }}
                >
                  404 error
                </h1>
              )}
            />
          </Switch>
        {/* </CSSTransition> */}
      {/* </TransitionGroup> */}
    </div>
  );
}

export default App;

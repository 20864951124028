import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { ContextInput } from "./../../context/Input/ContextInput.jsx";
import stateInput from "./../../context/Input/StateInput.jsx";
import ReducerInput from "./../../context/Input/ReducerInput.jsx";
import { Inputs } from "./../../modules/Inputs/Inputs.jsx";
import { Tools } from "./../../modules/Tools/Tools.jsx";
import { Field } from "../../modules/Field/Field";
import { ControlledCarousel } from "../../modules/Carousel/Carousel";
// import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import "./style.css";
import SendIcon from "@material-ui/icons/Send";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import Button from "@material-ui/core/Button";
// import withStyles from "@material-ui/styles/withStyles";
import { makeStyles } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import ImageUpload from "../../modules/ImageUpload/ImageUpload";

export default React.memo(() => {
  const [state, dispatch] = React.useReducer(ReducerInput, stateInput);
  const [print, setPrint] = useState(false);
  const printDocument = (e) => {
    dispatch({
      type: "BORDER_HANDLER",
    });
    setTimeout(() => {
      const saveDocument = document.getElementById("parents");
      html2canvas(saveDocument).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "px", "a4");
        var width = pdf.internal.pageSize.getWidth();
        var height = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "JPEG", 0, 0, width, height);
        // pdf.output('dataurlnewwindow');
        pdf.save("project.pdf");
      });
      setPrint(true);
    });
  };

  // const BootstrapButton = withStyles({
  //   root: {
  //     boxShadow: "none",
  //     textTransform: "none",
  //     fontSize: 16,
  //     padding: "6px 12px",
  //     border: "1px solid",
  //     lineHeight: 1.5,
  //     backgroundColor: "#0063cc",
  //     borderColor: "#0063cc",
  //     fontFamily: [
  //       "-apple-system",
  //       "BlinkMacSystemFont",
  //       '"Segoe UI"',
  //       "Roboto",
  //       '"Helvetica Neue"',
  //       "Arial",
  //       "sans-serif",
  //       '"Apple Color Emoji"',
  //       '"Segoe UI Emoji"',
  //       '"Segoe UI Symbol"',
  //     ].join(","),
  //     "&:hover": {
  //       backgroundColor: "#0069d9",
  //       borderColor: "#0062cc",
  //       boxShadow: "none",
  //     },
  //     "&:active": {
  //       boxShadow: "none",
  //       backgroundColor: "#0062cc",
  //       borderColor: "#005cbf",
  //     },
  //     "&:focus": {
  //       boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  //     },
  //   },
  // })(Button);

  //   const useStyles = makeStyles((theme) => {
  //     margin: {
  //       margin: theme.spacing(1);
  //     }
  //   });
  const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
      color: "#fff",
      zIndex: 1,
      marginLeft: "20px",
    },
    position: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      zIndex: 1,
    },
  }));

  const classes = useStyles();

  return (
    <ContextInput.Provider value={{ state, dispatch }}>
      <div className="constructor__container">
        <div className="inputs__container">
          <Inputs />
          <div className={classes.position}>
            <Button
              variant="contained"
              color="primary"
              className={classes.margin}
              onClick={printDocument}
              startIcon={<SaveIcon />}
              size="small"
            >
              Save Project
            </Button>
            <Tooltip title={!print ? "Please save your project" : ""} arrow>
              <span className="send__btn">
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.margin}
                  startIcon={<SendIcon />}
                  disabled={!print ? true : false}
                  size="small"
                >
                  <NavLink to="/contact" className="Link-btn">
                    Send Project
                  </NavLink>
                </Button>
              </span>
            </Tooltip>
          </div>
        </div>
        <div className="tools__container">
          <div>
            <Tools />
          </div>

          <div>
            <Field />
          </div>
          {/* <div className="image__container">
            <ImageUpload />
          </div> */}
          {/* <NavLink to="/calculator" style={{position:'absolute', top:'470px',left:'0', color: '#fff'}}>Calculator</NavLink> */}
        </div>

        {/* <div className="bg__slider">
            <ControlledCarousel></ControlledCarousel>
          </div> */}
      </div>
    </ContextInput.Provider>
  );
});

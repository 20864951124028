import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ContextInput } from "./../../context/Input/ContextInput.jsx";
import "./Carousel.css";

export const ControlledCarousel = () => {
  const { dispatch, ...stateInputs } = React.useContext(ContextInput);

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 1000,
  //   slidesToShow: 3,
  //   slidesToScroll: 3,
  //   adaptiveHeight: true
  // }
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    // customPaging: i => (
    //   <div
    //     style={{
    //       width: "30px",
    //       color: "blue",
    //       border: "1px blue solid",
    //       marginTop: '50px'
    //     }}
    //   >
    //     {i + 1}
    //   </div>
    // ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const changeBgCard = (slide) => {
    dispatch({
      type: "GHANGE_BGCARD",
      payload: slide,
    });
  };
  // console.log(stateInputs.state.imagesSlider)
  return (
    <div>
      <Slider {...settings}>
        {stateInputs.state.imagesSlider.map((slide, index) => {
          return (
            <div key={index} style={{ marginTop: "10%" }}>
              <div
                style={{
                  height: 150,
                  overflow: "hidden",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "0px",
                  padding: "5px",
                  width: "95%",
                  zIndex: 999,
                }}
                onClick={() => changeBgCard(slide)}
              >
                <img style={{ width: "100%" }} src={slide} alt="" />
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
